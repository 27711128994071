import PropTypes, { string } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { NavLink as Link } from "react-router-dom";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import { whiteBgForLogo, getLogoBySymbolAndName } from "utils/utils";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: "15%",
    paddingRight: "10%",
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0,
    },
  },
  logo: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: 200,
      marginBottom: 30,
      "&.whiteBg": {
        backgroundColor: "white",
        borderRadius: "50%",
      },
    },
    "& h5": {
      color: "unset",
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& img": {
        width: 130,
        marginBottom: 30,
      },
    },
  },
  text4626: {
    position: "absolute",
    color: "darkblue",
    backgroundColor: "cadetblue",
    borderRadius: 40,
    fontWeight: 900,
    padding: "2px 15px",
    marginTop: -6,
    marginLeft: 110,
    fontSize: "40px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px !important",
      marginLeft: 70,
    },
  },
  descriptionContainer: {
    width: "45%",
    textAlign: "left",
    "& p": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "2rem",
    marginBottom: 15,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  description: {},
  action: {
    paddingBottom: 30,
    display: "flex",
    gap: 50,
    justifyContent: "center",
    width: "100%",
    "& button": {
      fontSize: "1.25rem",
      width: 150,
      lineHeight: "normal",
      height: 50,
      boxShadow: "0px 0px 0px 10px #141414",
      "&:hover": {
        color: theme.palette.secondary.main,
        background: theme.palette.primary.light,
        boxShadow: "0px 0px 0px 10px #141414",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: 30,
      "& button": {
        fontSize: "1.25rem",
        width: 120,
        height: 50,
      },
    },
  },
}));

const CoinDescription = ({ name, symbol, address, underlying, description, chainId }) => {
  const classes = useStyles();
  const logo = useMemo(
    () => getLogoBySymbolAndName(symbol, name, address, chainId),
    [symbol, name, address, chainId]
  );

  return (
    <Box>
      <Grid className={classes.container}>
        <Grid item className={classes.logo}>
          <Grid item alignItems="center" spacing={1} wrap="nowrap">
            {underlying?.length === 2 && (
              <Grid container>
                <Grid item>
                  <img
                    src={underlying[0].logo}
                    alt={underlying[0].symbol}
                    className={whiteBgForLogo(symbol) ? "whiteBg" : ""}
                  />
                </Grid>
                <Grid item style={{ marginLeft: "-10%" }}>
                  <img
                    src={underlying[1].logo}
                    alt={underlying[1].symbol}
                    className={whiteBgForLogo(symbol) ? "whiteBg" : ""}
                  />
                </Grid>
              </Grid>
            )}
            {underlying?.length === 1 && (
              <Grid container>
                <Grid item>
                  <img
                    src={underlying[0].logo}
                    alt={underlying[0].symbol}
                    className={whiteBgForLogo(symbol) ? "whiteBg" : ""}
                  />
                </Grid>
                <Grid item className={classes.text4626}>
                  4626
                </Grid>
              </Grid>
            )}
            {underlying?.length === 0 && (
              <img src={logo} alt={symbol} className={whiteBgForLogo(symbol) ? "whiteBg" : ""} />
            )}
          </Grid>
          <Typography variant="h5">LENDING MARKET</Typography>
        </Grid>
        <Grid className={classes.descriptionContainer}>
          <Typography variant="h1" className={classes.title}>
            Lend out, borrow against and margin trade your {name}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.action}>
        <Box className={classes.button}>
          <Link to="/lend">
            <Button>Lend</Button>
          </Link>
        </Box>
        <Box className={classes.button}>
          <Link to="/">
            <Button>Borrow</Button>
          </Link>
        </Box>
        <Box className={classes.button}>
          <Link to="/amplify">
            <Button>Amplify</Button>
          </Link>
        </Box>
        <Box className={classes.button}>
          <Link to="/margin-trade">
            <Button>Margin Trade</Button>
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

CoinDescription.propTypes = {
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  underlying: PropTypes.arrayOf(string).isRequired,
  description: PropTypes.string,
  address: PropTypes.string.isRequired,
};

CoinDescription.defaultProps = {
  symbol: "",
  description: "",
};

export default CoinDescription;
