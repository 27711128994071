import { useWallet } from "hooks";
import _get from "lodash/get";
import { useCallback, useMemo } from "react";

import { customUSDCSymbol } from "constants/usdcSymbol";
import { useProjectTokensQuery } from "hooks/contexts/ProjectTokenContext/ProjectTokenContext";
import { isWrapNative, wrapNativeToken } from "utils/addressUtils";
import {
  groupingBorrowLogs,
  groupingTotalStates,
  mappingToGetLendingToken,
  mappingToGetProjectList,
} from "./helperFn";

export const useFetchGraphData = () => {
  const { account, chainId } = useWallet();

  const { data, refetch, loading } = useProjectTokensQuery();

  const APY = useMemo(() => groupingTotalStates(data), [data]);

  const getLendingApy = useCallback(
    (tokenAddress) =>
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === tokenAddress),
    [APY]
  );

  const lendingTokens = useMemo(
    () => [...mappingToGetLendingToken(data, chainId)].filter((o) => !o?.isPaused),
    [data, chainId]
  );

  const usdcAddress = lendingTokens.find((o) => customUSDCSymbol.includes(o.symbol));
  localStorage.setItem("usdcToken", _get(usdcAddress, "address"));

  const getBorrowingApy = useCallback(
    (tokenAddress) =>
      [..._get(APY, "borrowing_apy", [])].find((o) => o.lendingTokenAddress === tokenAddress),
    [APY]
  );

  const updateDataGraph = useCallback(() => refetch(account), [refetch, account]);

  const { name, symbol, logo } = wrapNativeToken(chainId);
  const availableBorrowTokens = lendingTokens.map((token) => {
    const isNative = isWrapNative(token.address, chainId);
    if (isNative) {
      return {
        ...token,
        name,
        symbol,
        logo,
      };
    }
    return token;
  });

  return {
    projectTokenList: [...mappingToGetProjectList(data, chainId)].filter(
      (o) => !o?.isDepositPaused && !o?.isWithdrawPaused
    ),
    updateDataGraph,
    borrowLogs: groupingBorrowLogs(data),
    borrowLogsRaw: useMemo(() => _get(data, ["borrowLogs"]), [data]),
    availableBorrowTokens,
    isLoading: loading,
    APY,
    getLendingApy,
    getBorrowingApy,
  };
};
