import { EVENT_TYPES } from "app/constants";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { coinPropType } from "types/coin";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  DialogApplyButton,
  DialogLogo,
  DialogTotal,
  HealthFactorProgressBar,
  NumericTextField,
  Spinner,
} from "components";

import { useBorrowContext } from "context/contracts/BorrowContext";
import { useWallet } from "hooks";
import { useDebounce } from "hooks/common/useDebounce";
import { useCoinMutations } from "hooks/mutation";
import { get } from "lodash";
import { formatUnits, parseUnits } from "utils/number";
import { calculateSafetyBuffer, convertNumberHex, getLogoBySymbolAndName } from "utils/utils";
import { isWrapNative } from "utils/addressUtils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.common.black,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  depositInfo: {
    [theme.breakpoints.down("xs")]: {
      "& p, & span": {
        fontSize: 14,
      },
    },
  },
}));

const DepositModal = ({ data, onClose }) => {
  const {
    name = "",
    balance = 0,
    logo = "",
    allowance = 0,
    address: prjTokenAddress = "",
    symbol = "",
    decimal = 0,
    underlyingTokens,
  } = data;
  const context = useBorrowContext();

  const healthFactor = get(context, "healthFactor", {})[prjTokenAddress];
  const totalOutstandingAssets = get(context, "totalOutstandingAssets", {})[prjTokenAddress];
  const lendingTokenAddress = totalOutstandingAssets?.lendingToken;

  const prjTokenInfo = useMemo(
    () => ({
      lvr: get(context, "lvrByProjectTokens", {})[prjTokenAddress],
      decimal,
      depositedAmount: get(context, "depositedAssets", {})[prjTokenAddress]?.value,
      price: get(context, "priceOfTokens", {})[prjTokenAddress],
    }),
    [context, decimal, prjTokenAddress]
  );

  const lendingTokenInfo = useMemo(
    () =>
      lendingTokenAddress
        ? {
            lvr: get(context, "lvrLending", {})[lendingTokenAddress],
            decimal: get(context, "decimalOfContractToken", {})[lendingTokenAddress],
            totalOutstanding: totalOutstandingAssets.value,
            price: get(context, "priceOfTokens", {})[lendingTokenAddress],
          }
        : undefined,
    [context, lendingTokenAddress, totalOutstandingAssets]
  );

  const allowanceValue = useMemo(() => formatUnits(allowance, decimal), [allowance, decimal]);

  const classes = useStyles();
  const { connected, chainId } = useWallet();
  const [inputNum, setInputValue] = useState("");
  const inputValue = useDebounce(inputNum, 200);
  const [safetyBuffer, setSafetyBuffer] = useState(1 - 1 / healthFactor);
  const { isLoading, approve, deposit } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.deposit,
  });

  const maxValue = balance;
  const isDisabled =
    !connected || !inputValue || Number(inputValue) === 0 || Number(inputValue) > Number(maxValue);

  useEffect(() => {
    if (inputValue) {
      if (!lendingTokenAddress) setSafetyBuffer(1);
      else {
        const lvrNumerator =
          Number(formatUnits(lendingTokenInfo.totalOutstanding, lendingTokenInfo.decimal)) *
          Number(lendingTokenInfo.price);
        const lvrDemonirator =
          Number(prjTokenInfo.lvr) *
          Number(lendingTokenInfo.lvr) *
          (Number(formatUnits(prjTokenInfo.depositedAmount, prjTokenInfo.decimal)) +
            Number(inputValue)) *
          Number(prjTokenInfo.price);

        if (lvrNumerator > lvrDemonirator) setSafetyBuffer(0);
        else setSafetyBuffer(1 - lvrNumerator / lvrDemonirator);
      }
    } else {
      const newSafetyBuffer = calculateSafetyBuffer(healthFactor);
      setSafetyBuffer(newSafetyBuffer);
    }
  }, [healthFactor, inputValue, lendingTokenAddress, lendingTokenInfo, prjTokenInfo]);

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve();
  };
  const handleDeposit = async () => {
    try {
      if (!prjTokenAddress) return;

      const amount = parseUnits(convertNumberHex(inputValue), decimal).toString();

      await deposit(amount);
    } finally {
      onClose();
      resetInputValue();
    }
  };

  const needToEnable = useMemo(() => {
    const isNative = isWrapNative(prjTokenAddress, chainId);
    if (isNative) return false;
    return !(allowance && Number(allowanceValue) >= Number(inputValue));
  }, [allowance, allowanceValue, chainId, inputValue, prjTokenAddress]);

  return (
    <>
      <DialogLogo
        logoUrl={logo}
        name={name}
        isLPToken={underlyingTokens?.length > 0}
        logoLPOne={
          underlyingTokens?.length
            ? getLogoBySymbolAndName(
                underlyingTokens[0].symbol,
                underlyingTokens[0].name,
                underlyingTokens[0]?.address,
                chainId
              )
            : ""
        }
        logoLPTwo={
          underlyingTokens?.length
            ? getLogoBySymbolAndName(
                underlyingTokens[1].symbol,
                underlyingTokens[1].name,
                underlyingTokens[1]?.address,
                chainId
              )
            : ""
        }
      />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          maxValue={maxValue}
          addressToken={prjTokenAddress}
          decimalToken={decimal}
        />

        <Box px={2} py={2} mt={2} className={classes.contentInner}>
          <HealthFactorProgressBar value={safetyBuffer} isSafetyBuffer />
        </Box>

        <Box className={classes.depositInfo}>
          {!needToEnable ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleDeposit}>
              Deposit
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={!connected} onClick={handleEnable}>
              Enable
            </DialogApplyButton>
          )}
          <DialogTotal title="Wallet Balance" value={balance} currency={symbol} />
        </Box>
      </Box>
    </>
  );
};

DepositModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepositModal;
