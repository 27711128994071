/* eslint-disable prefer-destructuring */
import { useCallback, useMemo } from "react";
import { ERC20TokenABI, MultiCallZkSyncABI } from "utils/ethereum/abi";
import { formatUnits } from "utils/number";
import {
  isWrapNative,
  wrapNativeToken,
  ZERO_ADDRESS,
  MainNetwork,
  TestNetwork,
} from "utils/addressUtils";
import { REACT_APP_MULTICALL } from "constants/NetworkChainId";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { useQuery } from "react-query";
import {
  GET_PRJ_TOKEN_WITHOUT_ACCOUNT,
  getPrice,
} from "pages/BorrowerNewDashboard/hooks/useTokenSupported";
import {
  getPriceProviderAggregatorContract,
  getPriceProviderAggregatorZksyncContract,
  getPrimaryIndexToken,
  getPrimaryIndexTokenZksync,
} from "utils/ethereum/contracts";
import { GET_MARKET_STATE, getLatestValue } from "hooks/query/graphQL/useMarketChart";
import { getClient } from "utils/client/graphql.client";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";
import { USD_DECIMAL } from "constants/contract";

const networks = process.env.REACT_APP_NETWORK === "mainnet" ? MainNetwork : TestNetwork;

const decodeMulticallResult = (results, requests) => {
  const decoded = requests.map((data, index) => {
    const { methodName, iface } = data;
    const result = results[index]?.returnData;

    let decodeValue;

    try {
      decodeValue = iface.decodeFunctionResult(methodName, result);
    } catch (e) {
      if (methodName === "name" || methodName === "symbol") {
        decodeValue = utils.parseBytes32String(result);
      } else {
        throw e;
      }
    }

    return {
      methodName,
      value: decodeValue,
    };
  });

  const returnedData = {};
  decoded.forEach((data) => {
    returnedData[data.methodName] = data.value;
  });

  return returnedData;
};

const getClientByChainNumber = (chainId) => getClient(`0x${Number(chainId).toString(16)}`);

export const useAllTokens = () => {
  const tokenInAllNetworks = useQuery(["get-all-token-data"], async () => {
    const promises = [];
    networks.forEach((network) => {
      const client = getClientByChainNumber(network.id);
      promises.push(client.query({ query: GET_PRJ_TOKEN_WITHOUT_ACCOUNT }));
    });
    const tokenData = await Promise.all(promises);
    const result = [];
    tokenData.forEach((res, index) => {
      const network = networks[index];
      result.push({
        ...res.data,
        network,
      });
    });
    return result;
  });

  return tokenInAllNetworks;
};

export const useTokenInfo = (address, chainId) => {
  const selectedChainId = useMemo(() => `0x${Number(chainId).toString(16)}`, [chainId]);
  const provider = useMemo(() => {
    const network = networks.find((n) => n.id === Number(chainId));
    if (!network) return null;
    return new ethers.providers.JsonRpcProvider(network.rpcUrls.default.http[0], {
      chainId: Number(chainId),
      name: network.name,
    });
  }, [chainId]);

  const PitInstance = useMemo(() => getPrimaryIndexToken(selectedChainId), [selectedChainId]);
  const PriceInstance = useMemo(
    () => getPriceProviderAggregatorContract(selectedChainId),
    [selectedChainId]
  );

  const multiCallContract = useMemo(() => {
    if (!provider) return null;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [selectedChainId, provider]);

  const results = useQuery(
    ["landing-token-info", address],
    async () => {
      const erc20Iface = new utils.Interface(ERC20TokenABI);
      const pitIface = new utils.Interface(PitInstance.abi);
      const priceIface = new utils.Interface(PriceInstance.abi);
      const listRequest = [];
      listRequest.push(
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("name", []),
          reference: address,
          methodName: "name",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("symbol", []),
          reference: address,
          methodName: "symbol",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: address,
          iface: erc20Iface,
          callData: erc20Iface.encodeFunctionData("decimals", []),
          reference: address,
          methodName: "decimals",
          methodParameters: [],
          value: 0,
          contractName: "ERC20",
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData("lendingTokenInfo", [address]),
          reference: address,
          methodName: "lendingTokenInfo",
          methodParameters: [address],
          value: 0,
          contractName: "PITContract",
        },
        {
          target: PitInstance.address,
          iface: pitIface,
          callData: pitIface.encodeFunctionData("borrowLimitPerLendingToken", [address]),
          reference: address,
          methodName: "borrowLimitPerLendingToken",
          methodParameters: [address],
          value: 0,
          contractName: "PITContract",
        },
        {
          target: PriceInstance.address,
          iface: priceIface,
          callData: priceIface.encodeFunctionData("tokenPriceProvider", [address]),
          reference: address,
          methodName: "tokenPriceProvider",
          methodParameters: [address],
          value: 0,
          contractName: "PriceContract",
        }
      );
      const multicallRes = await multiCallContract.callStatic
        .aggregate3Value(listRequest, {
          value: 0,
        })
        .catch((error) => {
          console.log("Request multicall fail", listRequest);
          throw error;
        });
      const values = decodeMulticallResult(multicallRes, listRequest);
      return values;
    },
    { enabled: !!address }
  );

  const tokenInfo = useMemo(() => {
    const isNative = isWrapNative(address, selectedChainId);
    const nativeCoin = wrapNativeToken(selectedChainId);
    const token = {
      name: "",
      symbol: "",
      decimal: 18,
      lvr: 0,
      debtLimitUds: 0,
      bLendingToken: "",
      priceProvider: "",
    };
    if (results.isFetched && results.isSuccess) {
      const {
        name,
        symbol,
        decimals,
        lendingTokenInfo,
        borrowLimitPerLendingToken,
        tokenPriceProvider,
      } = results.data;
      token.name = isNative ? nativeCoin.name : name[0];
      token.symbol = isNative ? nativeCoin.symbol : symbol[0];
      token.decimal = decimals[0];
      token.debtLimitUds = formatUnits(borrowLimitPerLendingToken[0], 6);
      token.lvr = lendingTokenInfo[3].numerator / lendingTokenInfo[3].denominator;
      token.bLendingToken = lendingTokenInfo[2];
      token.priceProvider = tokenPriceProvider[0];
    }
    return token;
  }, [address, results, selectedChainId]);

  return {
    loading: false,
    ...tokenInfo,
  };
};

export const useBlendingTokenInfo = (address, chainId) => {
  const marketData = useQuery(
    ["get-all-market-data", address],
    async () => {
      const allResults = {};
      const client = getClientByChainNumber(chainId);
      const { data } = await client.query({
        query: GET_MARKET_STATE,
        variables: {
          lendingToken: address,
          skip: 0,
          startAt: 0,
          limit: 1000,
        },
      });

      // eslint-disable-next-line no-loop-func
      Object.keys(data).forEach((key) => {
        if (!allResults[key]) {
          allResults[key] = [];
        }
        allResults[key] = allResults[key].concat(data[key]);
      });
      return allResults;
    },
    { enabled: !!address }
  );

  const [totalSupplyUsd, totalBorrowsUsd, borrowApy, lenderApy] = useMemo(() => {
    if (!!address && marketData.data) {
      const {
        borrowingAPYHistories,
        lenderAPYHistories,
        outstandingHistories,
        lenderAggregateCapitalDepositedHistories,
      } = getLatestValue(marketData.data);

      return [
        lenderAggregateCapitalDepositedHistories?.amount,
        outstandingHistories?.amount,
        borrowingAPYHistories?.amount,
        lenderAPYHistories?.amount,
      ];
    }
    return [0, 0, 0, 0];
  }, [address, marketData.data]);

  return {
    loading: marketData.isLoading,
    totalSupplyUsd,
    totalBorrowsUsd,
    borrowApy,
    lenderApy,
  };
};

export const usePriceToken = (address, underlyingTokens = [], chainId) => {
  const selectedChainId = useMemo(() => `0x${Number(chainId).toString(16)}`, [chainId]);
  const provider = useMemo(() => {
    const network = networks.find((n) => n.id === Number(chainId));
    if (!network) return null;
    return new ethers.providers.JsonRpcProvider(network.rpcUrls.default.http[0], {
      chainId: Number(chainId),
      name: network.name,
    });
  }, [chainId]);
  const multiCallContract = useMemo(() => {
    if (!provider) return null;
    const multiCallAddress = REACT_APP_MULTICALL[selectedChainId];

    const multiCallSMC = new Contract(multiCallAddress, MultiCallZkSyncABI, provider);

    return multiCallSMC;
  }, [selectedChainId, provider]);

  const PriceInstance = useMemo(
    () => getPriceProviderAggregatorZksyncContract(selectedChainId),
    [selectedChainId]
  );

  const PitInstance = useMemo(() => getPrimaryIndexTokenZksync(selectedChainId), [selectedChainId]);

  const getPriceFromPit = useCallback(async () => {
    const PitContract = new Contract(PitInstance.address, PitInstance.abi, provider);
    const PriceContract = new Contract(PriceInstance.address, PriceInstance.abi, provider);
    const TokenContract = new Contract(address, ERC20TokenABI, provider);
    const tokensNeedToUpdatePrice =
      underlyingTokens?.length > 0
        ? underlyingTokens.map((underlying) => underlying.address)
        : [address];
    const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
      Array.from(new Set(tokensNeedToUpdatePrice)),
      PriceContract
    );

    const decimals = await TokenContract.decimals();
    const oneToken = BigNumber.from(10).pow(decimals);
    const price = await PitContract.callStatic.getTokenEvaluationWithUpdatePrices(
      address,
      oneToken,
      priceIds,
      updateData,
      { value: payableAmount }
    );
    return (Number(price) / 10 ** Number(USD_DECIMAL)).toString();
  }, [PitInstance, PriceInstance, address, provider, underlyingTokens]);

  const getPriceToken = useQuery(
    ["get-price-token", address],
    async () => {
      try {
        const prices = await getPrice(
          [{ address, underlyingTokens }],
          PriceInstance,
          multiCallContract,
          provider
        );
        return prices[address];
      } catch (error) {
        const price = await getPriceFromPit();
        return price;
      }
    },
    {
      enabled: !!address && address !== ZERO_ADDRESS && !!provider,
    }
  );

  return {
    price: getPriceToken.data,
    error: getPriceToken.isError,
    isLoading: getPriceToken.isLoading,
  };
};
