import { constants, ethers, providers } from "ethers";
import {
  arbitrumGoerli,
  goerli,
  optimismGoerli,
  polygonMumbai,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  zkSyncTestnet,
  zkSync,
} from "wagmi/chains";
import { ReactComponent as EthereumIcon } from "assets/ethereum.svg";
import { ReactComponent as PolygonIcon } from "assets/polygon.svg";
import { ReactComponent as ArbitrumIcon } from "assets/svg/arbitrum.svg";
import { ReactComponent as OptimismIcon } from "assets/svg/optimism.svg";
import { ReactComponent as ZkSyncIcon } from "assets/zksync.svg";
import { CHAINID_NETWORK, REACT_APP_WRAP_NATIVE_TOKEN } from "constants/NetworkChainId";
import ethIcon from "assets/icons/eth.png";
import { getLogoBySymbolAndName, getSpecialTokenSymbol } from "utils/utils";
import { NATIVE_TOKEN } from "app/constants";
import { toHex } from "./number";

export const isEqualLowerString = (ad1 = constants.AddressZero, ad2 = constants.AddressZero) =>
  ad1.toLowerCase() === ad2.toLowerCase();

export const isZksyncNetwork = (chainId) => chainId === process.env.REACT_APP_CHAIN_ID_ZKSYNC;
export const isSupportZkSync = () => process.env.REACT_APP_SUPPORT_ZKSYNC === "true";

export const isIncludeNetwork = {
  polygon: process.env.REACT_APP_INCLUDE_POLYGON === "true",
  ethereum: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
  arbitrum: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
  optimism: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
  zkSync: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
};

const isIncludeNetworkArray = [
  {
    name: "polygon",
    isOn: process.env.REACT_APP_INCLUDE_POLYGON === "true",
    mainnet: polygon,
    testnet: polygonMumbai,
  },
  {
    name: "ethereum",
    isOn: process.env.REACT_APP_INCLUDE_ETHEREUM === "true",
    mainnet,
    testnet: goerli,
  },
  {
    name: "arbitrum",
    isOn: process.env.REACT_APP_INCLUDE_ARBITRUM === "true",
    mainnet: arbitrum,
    testnet: arbitrumGoerli,
  },
  {
    name: "optimism",
    isOn: process.env.REACT_APP_INCLUDE_OPTIMISM === "true",
    mainnet: optimism,
    testnet: optimismGoerli,
  },
  {
    name: "zkSync",
    isOn: process.env.REACT_APP_INCLUDE_ZKSYNC === "true",
    mainnet: zkSync,
    testnet: zkSyncTestnet,
  },
];

export const TestNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.testnet);

export const MainNetwork = isIncludeNetworkArray
  .filter((network) => network.isOn)
  .map((network) => network.mainnet);

export const MainNetworkSupported = [1, 137, 42161, 10, 324];
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const isMainNetWork = (chainId) => MainNetworkSupported.includes(+chainId);

const getDefaultNetwork = () => {
  const networks = process.env.REACT_APP_NETWORK === "mainnet" ? MainNetwork : TestNetwork;
  const defaultNetworkId = process.env.REACT_APP_DEFAULT_CHAIN_ID;
  const defaultNetwork = networks.find(
    (network) => defaultNetworkId === `0x${network.id.toString(16)}`
  );

  if (!defaultNetwork) {
    return process.env.REACT_APP_NETWORK === "mainnet" ? mainnet : goerli;
  }
  return defaultNetwork;
};

export const defaultNetwork = getDefaultNetwork();
export const defaultProvider = new providers.JsonRpcProvider(
  process.env.REACT_APP_DEFAULT_RPC_URL || defaultNetwork.rpcUrls.default.http[0]
);

export const wrapNativeToken = (chainId) => {
  const defaultNetworkId = `0x${defaultNetwork.id.toString(16)}`;
  const address = REACT_APP_WRAP_NATIVE_TOKEN[chainId || defaultNetworkId];
  if (!address) return {};
  const { logo, name, symbol } = NATIVE_TOKEN[chainId || defaultNetworkId] || {};
  return {
    address,
    name,
    symbol,
    logo: logo || getLogoBySymbolAndName(symbol, name, address, chainId) || ethIcon,
  };
};

export const isWrapNative = (address, chainId) => {
  const { address: nativeAddress } = wrapNativeToken(chainId);
  return address.toLowerCase() === nativeAddress?.toLowerCase();
};

const getTokenSymbolByUnderlying = (address, underlyingTokens, symbol, chainId) => {
  const nativeToken = wrapNativeToken(`0x${Number(chainId).toString(16)}`);
  if (underlyingTokens?.length === 2) {
    const isNative0 = isWrapNative(
      underlyingTokens[0].address,
      `0x${Number(chainId).toString(16)}`
    );
    const isNative1 = isWrapNative(
      underlyingTokens[1].address,
      `0x${Number(chainId).toString(16)}`
    );
    const symbol0 = isNative0 ? nativeToken.symbol : underlyingTokens[0].symbol;
    const symbol1 = isNative1 ? nativeToken.symbol : underlyingTokens[1].symbol;
    return `${symbol0}/${symbol1}`;
  }
  return getSpecialTokenSymbol(address, chainId) || symbol;
};

export const mappingTokenInfo = (token, chainId) => {
  if (!token) {
    return token;
  }
  const isNative = isWrapNative(token.address, ethers.utils.hexValue(chainId));
  if (isNative) {
    const { name, symbol, logo } = wrapNativeToken(ethers.utils.hexValue(chainId));
    return {
      ...token,
      name,
      symbol,
      logo,
    };
  }
  const underlyingTokens = token.underlyingTokens?.map((x) => mappingTokenInfo(x, chainId));
  const name = getTokenSymbolByUnderlying(token.address, underlyingTokens, token.name, chainId);
  const symbol = getTokenSymbolByUnderlying(token.address, underlyingTokens, token.symbol, chainId);

  return {
    ...token,
    name,
    symbol: getSpecialTokenSymbol(token.address, chainId) || symbol,
    underlyingTokens,
    logo:
      getLogoBySymbolAndName(symbol, name, token?.address, chainId) ||
      "https://s2.coinmarketcap.com/static/img/coins/64x64/3267.png",
  };
};

export const getNetworkLogoById = (chainId) => {
  const hexId = toHex(chainId);
  switch (hexId) {
    case CHAINID_NETWORK.ethereum:
      return EthereumIcon;
    case CHAINID_NETWORK.polygon:
      return PolygonIcon;
    case CHAINID_NETWORK.optimism:
      return OptimismIcon;
    case CHAINID_NETWORK.arbitrum:
      return ArbitrumIcon;
    case CHAINID_NETWORK.zkSync:
      return ZkSyncIcon;
    default:
      return null;
  }
};
