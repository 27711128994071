import { useMemo } from "react";
import PropTypes from "prop-types";
import CoinSummary from "components/AssetLanding/CoinSummary";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CoinDescription from "components/AssetLanding/CoinDescription";
import CoinStats from "components/AssetLanding/CoinStats";
import { usePriceToken } from "hooks/contract/useTokenStats";
import { useWallet } from "hooks";
import { defaultNetwork, mappingTokenInfo } from "utils/addressUtils";

const useStyles = makeStyles((theme) => ({
  coinSummary: {
    marginLeft: "auto",
    marginRight: "auto",
    borderTop: "2px solid #6e6e6e",
    borderBottom: "2px solid #6e6e6e",
  },
  coinDescription: {
    marginTop: 50,
  },
  coinStats: {
    marginTop: 30,
    borderTop: "1px solid #2B2B2B",
  },
  path: {
    color: "#ffffff",
    paddingLeft: "15%",
    paddingBottom: 10,
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const AssetLandingPage = ({ coins, description, lendingMarketLogo, symbol, name, underlying }) => {
  const classes = useStyles();
  const { chainId, connected } = useWallet();
  const selectedChainId = useMemo(
    () => (connected ? chainId : `0x${Number(defaultNetwork.id).toString(16)}`),
    [chainId, connected]
  );
  const { price: tokenPrice, isLoading } = usePriceToken(
    coins[0]?.address,
    underlying,
    coins[0]?.network.id
  );

  const underlyingTokens = useMemo(() => {
    if (!underlying) {
      return [];
    }
    return underlying.map((token) => mappingTokenInfo(token, selectedChainId));
  }, [selectedChainId, underlying]);
  const price = useMemo(() => {
    if (tokenPrice) {
      return tokenPrice;
    }
    return null;
  }, [tokenPrice]);

  return (
    <>
      <div>
        <Box className={classes.path}>
          {"Market > "} {symbol}
        </Box>
      </div>
      <div className={classes.coinSummary}>
        <CoinSummary
          underlying={underlyingTokens}
          address={coins?.[0].address}
          chainId={coins?.[0].network ? Number(coins?.[0].network.id) : undefined}
          symbol={symbol}
          name={name}
          lendingMarketLogo={lendingMarketLogo}
          price={price}
          isLoading={isLoading}
        />
      </div>
      <div className={classes.coinDescription}>
        <CoinDescription
          description={description}
          name={name}
          address={coins?.[0].address}
          symbol={symbol}
          underlying={underlyingTokens}
          chainId={coins?.[0].network ? Number(coins?.[0].network.id) : undefined}
        />
      </div>
      <div className={classes.coinStats}>
        <CoinStats coins={coins} name={name} symbol={symbol} price={price} />
      </div>
    </>
  );
};

AssetLandingPage.propTypes = {
  lendingMarketLogo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default AssetLandingPage;
